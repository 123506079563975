<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
            <PaymentValidation @paymentDone="$emit('paymentDone')" @back="$emit('hideDialog')" inComponent/>
        </v-card>
    </v-dialog>
</template>

<script>
import PaymentValidation from "@/components/PaymentValidation"
    export default {
        components: {
            PaymentValidation,
        },
        data() {
            return {
                dialog: true
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>