<template>
	<div class="payment-validation">
		<div class="payment-validation-header">
			<v-icon class="payment-validation-back" @click="handleBack()">mdi-arrow-left</v-icon>
			<span>{{$t('myBank')}}</span>
		</div>

		<v-card class="ma-6 pa-4 d-flex flex-column align-center payment-body" flat>
			<div class="d-flex payment-body-icons-wrapper">
				<v-img class="drawer-header-logo" lazy-src="@/assets/img/wl-horizontal-logo.png" contain
					max-height="120px" max-width="120px" src="@/assets/img/wl-horizontal-logo.png"></v-img>
				<v-spacer></v-spacer>
				<v-img class="drawer-header-logo" lazy-src="@/assets/img/verified-visa.png" contain max-height="72px"
					max-width="72px" src="@/assets/img/verified-visa.png"></v-img>
			</div>
			<span class="text-h6">{{$t('secureAuthent')}}</span>
        	<span class="align-self-start text-caption">{{$t('authentFinger')}}</span>
			<div class="align-self-start my-3 payment-info">
				<span class="text-caption payment-info-title">{{$t('merchant')}}</span>
				<span class="text-caption payment-info-value">{{config.shopName}}</span>
				<span class="text-caption payment-info-title">{{$t('date')}}</span>
				<span class="text-caption payment-info-value">{{getCurrentDate()}}</span>
				<span class="text-caption payment-info-title">{{$t('cardNumber')}}</span>
				<span class="text-caption payment-info-value">{{cardNumber}}</span>
			</div>
			<span class="align-self-start text-caption font-italic payment-info-explain">{{$t('fingerAuthentError')}}</span>
		</v-card>

		<v-spacer></v-spacer>

		<div v-if="timer != null" class="mb-10 d-flex flex-column align-center noselect">
			<v-progress-circular v-if="!check" indeterminate color="primary" size="64"></v-progress-circular>
			<v-icon size="64" class="animate__animated animate__zoomIn" v-else color="green">mdi-check</v-icon>
		</div>

		<v-icon class="mb-10 " v-touch="{ start: () => pressHandler(), end: () => pressHandler(true) }" size="64">
			mdi-fingerprint
		</v-icon>

	</div>
</template>
<script>
	import { mapState } from 'vuex'
	export default {
		data() {
			return {
				check: false,
				press: null,
				startDate: null,
				timer: null
			}
		},
		props: {
			inComponent: {
				type: Boolean,
				default: false
			},
		},
		computed:{
        ...mapState('customer', ['cardNumber'])
    	},
		mounted() {
			clearTimeout(this.timer)
		},
		methods: {
			pressHandler(pressEnded = false) {
				if (!pressEnded) {
					this.startDate = new Date();
					this.timer = setTimeout(() => {
						this.check = true
						setTimeout(() => {
							this.check = false
							this.timer = null
                            // if(this.$route.query && this.$route.query.customerPayment){
                            //     this.$router.push({name: 'TicketBank'})
                            // }else{
                            //     this.$router.push("ticket")
                            // }
							this.$emit("paymentDone")
						}, 1000);

					}, 1500);
				} else {
					let endDate = new Date();
					var diff = endDate - this.startDate;
					if (diff < 1500) {
						clearTimeout(this.timer)
						this.timer = null
					}
				}
			},
			handleBack(){
				if(this.inComponent){
					this.$emit("back")
				}else{
					this.$router.go(-1)
				}
			}
		},
	}
</script>

<style scoped>
	.payment-validation {
		height: 100%;
		flex-direction: column;
		display: flex;
	}

	.payment-validation-header {
		background-color: #005A8C;
		color: white;
		height: 48px;
	}

	.payment-validation-back {
		position: absolute;
		color: white;
		font-size: 24px;
		margin: 12px 0 0 12px;
	}

	.payment-validation-header span {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.25rem;
	}

	* {
		-webkit-touch-callout: none !important;
		-webkit-user-select: none !important;
		-khtml-user-select: none !important;
		-moz-user-select: none !important;
		-ms-user-select: none !important;
		user-select: none !important;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
	}

	.payment-validation {
		position: fixed;
	}

	.payment-header {
		width: 100%;
		flex: 0;
	}

	.payment-body-icons-wrapper {
		width: 100%;
	}

	.payment-body {
		border: 1px solid;
	}

	.payment-info {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(3, 1fr);
	}

	.payment-info-explain {
		font-size: .55rem !important;
	}
</style>
