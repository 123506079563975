<template>
    <div v-if="showBasket" class="customer-payment full-height d-flex flex-column">
        <div class="d-flex flex-column align-center justify-center pt-4 pb-6 primary">
            <v-avatar color="indigo lighten-5" size="120" tile>
                <img v-if="basketProducts && basketProducts.length > 0" :src="getProductImgUrl(basketProducts[0].product)" />
            </v-avatar>
            <v-chip class="mt-n3 text-caption font-weight-bold" small color="white" @click="$root.$emit('showBasketHeader')" >
                <span>{{$tc("customerPaymentArticlesNumber", nbrProductsInBasket, {nbArticles: nbrProductsInBasket})}} </span>
                <v-icon small>mdi-chevron-right</v-icon>
            </v-chip>

            <div class="mt-4 d-flex flex-column align-center">
                <p class="ma-0 text-body-1 indigo--text text--lighten-5">{{$t("customerPaymentTotal")}}</p>
                <p class="ma-0 font-weight-medium text-h5 white--text">{{formatPrice(totalAmount)}}</p>
            </div>

        </div>
        <div class="d-flex flex-column mt-3 px-4">
    
            <!-- <p class="primary--text font-weight-bold text-h6">{{$t('choosePaymentMethod')}}</p> -->
    
            <div class="d-flex align-center flex-column" v-if="basketTotalAmount && basketTotalAmount > 0">
               
                <template v-if="!customerPayment.selectedPaymentMethods || customerPayment.selectedPaymentMethods.includes('BNPL') || customerPayment.selectedPaymentMethods.includes('osWallet')">
                    <div class="d-flex align-stretch flex-column">
                        <template v-if="!customerPayment.selectedPaymentMethods || customerPayment.selectedPaymentMethods.includes('osWallet')">
                            <ApplePay v-if="deviceType == 'ios'" :amount="totalAmount" :paymentConfig="paymentMethods.find(method => method.id == 3).config" :nextRoute="'/ticketbank'"/>
                            <GooglePay v-else :amount="`${totalAmount}`" :paymentConfig="paymentMethods.find(method => method.id == 4).config" :nextRoute="'/ticketbank'"/>
                        </template>
                        <v-btn v-if="!customerPayment.selectedPaymentMethods || customerPayment.selectedPaymentMethods.includes('BNPL')" class="my-3" color="primary">{{$t("settingsBNPL")}}</v-btn>
                    </div>
                    
                    <div v-if="!customerPayment.selectedPaymentMethods || customerPayment.selectedPaymentMethods.includes('SIPS')" class="my-3 align-self-stretch d-flex align-center">
                        <v-divider class="mx-3"></v-divider>
                        <span class="text-body-2 font-weight-medium">{{$t("customerPaymentPayByCard")}}</span>
                        <v-divider class="mx-3"></v-divider>
                    </div>
                </template>

                <p v-if="customerPayment.shipping" class="ma-0 align-self-start text-body-1 font-weight-medium">{{$t("customerPaymentShippingInfo")}}</p>
                <div v-if="customerPayment.firstName ||customerPayment.lastName ||customerPayment.phoneNumber" class="mt-4 d-flex flex-column align-self-stretch">
                    <p class="ma-0 align-self-start text-body-2 font-weight-medium">{{$t("customerPaymentCustomerInfo")}}</p>
                    <v-text-field :placeholder="$t('customerPaymentFirstName')" :class="{'rounded-b-0': customerPayment.lastName}" color="primary" full-width dense hide-details outlined v-if="customerPayment.firstName"></v-text-field>
                    <v-text-field :placeholder="$t('customerPaymentLastName')" :class="{'rounded-t-0 without-top-border': customerPayment.firstName, 'rounded-b-0' : customerPayment.phoneNumber}" color="primary" full-width dense hide-details outlined v-if="customerPayment.lastName"></v-text-field>
                    <v-text-field :placeholder="$t('customerPaymentPhoneNumber')" type="tel" color="primary" :class="{'rounded-t-0 without-top-border': customerPayment.lastName || customerPayment.firstName }" full-width dense hide-details outlined v-if="customerPayment.phoneNumber"></v-text-field>
                </div>               
                <div v-if="customerPayment.email" class="mt-4 d-flex flex-column align-self-stretch">
                    <label class="mb-1 text-body-2 font-weight-medium" for="email">{{$t('customerPaymentEmail')}}</label>
                    <v-text-field id="email" type="email" :placeholder="$t('customerPaymentEmail')" color="primary" full-width dense hide-details outlined></v-text-field>
                </div>

                <template v-if="customerPayment.shipping">
                    <div class="my-4 d-flex flex-column">
                        <label class="mb-1 text-body-2 font-weight-medium" for="address">{{$t("customerPaymentShippingAddress")}}</label>
                        <v-text-field :placeholder="$t('customerPaymentAddress')" type="text" class="rounded-b-0" color="primary" full-width dense hide-details outlined></v-text-field>
                        <v-text-field :placeholder="$t('customerPaymentAddress')"  type="text" class="rounded-0 without-top-border" color="primary" full-width dense hide-details outlined></v-text-field>
                        <div class="d-flex align-center">
                            <v-text-field :placeholder="$t('customerPaymentZipCode')"  type="text" class="rounded-t-0 rounded-br-0 without-top-border" color="primary" width="50%" dense hide-details outlined></v-text-field>
                            <v-text-field :placeholder="$t('customerPaymentCity')"  type="text" class="rounded-t-0 rounded-bl-0 without-top-border without-left-border" color="primary" width="50%" dense hide-details outlined></v-text-field>
                        </div>
                    </div>
                    <p class="ma-0 align-self-start text-body-1 font-weight-medium">{{$t("customerPaymentShippingMethod")}}</p>
                    <v-list class="rounded py-0 shipping-list" outlined>
                        <v-radio-group hide-details class="ma-0" v-model="shipping" readonly>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-radio v-model="shipping" value="free" readonly></v-radio>
                                </v-list-item-avatar>
        
                                <v-list-item-content>
                                    <v-list-item-title class="mb-1 align-self-end ma-0 font-weight-bold text-body-2">{{$t("customerPaymentFreeShippingLabel")}}</v-list-item-title>
                                    <v-list-item-subtitle>{{$t("customerPaymentFreeShippingSubtitlte")}}</v-list-item-subtitle>
                                </v-list-item-content>
        
                                <v-list-item-action>
                                    <v-list-item-title class="mb-1 align-self-end ma-0 font-weight-bold text-body-2">0.00€</v-list-item-title>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-radio readonly></v-radio>
                                </v-list-item-avatar>
        
                                <v-list-item-content>
                                    <v-list-item-title class="mb-1 align-self-end ma-0 font-weight-bold text-body-2">{{$t("customerPaymentPaidShippingLabel")}}</v-list-item-title>
                                    <v-list-item-subtitle>{{$t("customerPaymentPaidShippingSubtitlte")}}</v-list-item-subtitle>
                                </v-list-item-content>
        
                                <v-list-item-action>
                                    <v-list-item-title class="mb-1 align-self-end ma-0 font-weight-bold text-body-2">+ 15.00€</v-list-item-title>
                                </v-list-item-action>
                            </v-list-item>
                        </v-radio-group>
                    </v-list>
                </template>
    
                <p class="ma-0 mt-4 align-self-start text-body-1 font-weight-medium">{{$t("customerPaymentPaymentDetails")}}</p>
                <div v-if="!customerPayment.selectedPaymentMethods || customerPayment.selectedPaymentMethods.includes('SIPS')" class="my-2 d-flex flex-column">
                    <label class="mb-1 text-body-2 font-weight-medium" for="address" @click="fillCards()">{{$t("customerPaymentCardInformation")}}</label>
                    <span v-if="showError" class="text-center red--text font-weight-bold text-caption">{{$t("paypageError")}}</span>

                    <v-text-field placeholder="4200 #### #### ####" v-model="cardNumber" :rules="[value => (new RegExp('[0-9][0-9][0-9][0-9] [0-9][0-9][0-9][0-9] [0-9][0-9][0-9][0-9] [0-9][0-9][0-9][0-9]')).test(value) || 'Required.']" v-mask="'#### #### #### ####'" type="text" class="rounded-b-0" color="primary" full-width dense hide-details outlined>
                        <template v-slot:append>
                            <div class="d-flex align-center">
                                <v-avatar class="mx-1" size="24" tile>
                                    <v-img src="@/assets/img/visa-logo.png" alt="alt" contain />
                                </v-avatar>
                                <v-avatar class="mx-1" size="24" tile>
                                    <v-img src="@/assets/img/mastercard-logo.png" alt="alt" contain />
                                </v-avatar>
                            </div>
                        </template>
                    </v-text-field>
                    <div class="d-flex align-center">
                        <v-text-field v-mask="'##'" placeholder="MM" :rules="[value => (new RegExp('[0-9][0-9]')).test(value) || 'Required.']" type="text" v-model="month" class="rounded-0 without-top-border rounded-bl" color="primary" width="25%" dense hide-details outlined></v-text-field>
                        <v-text-field v-mask="'####'" placeholder="YYYY" :rules="[value => (new RegExp('[0-9][0-9][0-9][0-9]')).test(value) || value != '' || 'Required.']" type="text" v-model="year" class="rounded-0 without-left-border without-top-border" color="primary" width="25%" dense hide-details outlined></v-text-field>
                        <v-text-field v-mask="'###'" placeholder="CVC" :rules="[value => (new RegExp('[0-9][0-9][0-9]')).test(value) || 'Required.']" type="text" v-model="cvv" class="rounded-0 without-top-border without-left-border rounded-br" color="primary" width="50%" dense hide-details outlined></v-text-field>
                    </div>
                </div>
                <v-btn color="primary" class="my-4" width="90%" @click="handlePayBtn()">{{$t("payPaymentmethods", {amountToPay: formatPrice(totalAmount)})}}</v-btn>
                <PaymentValidationDialog v-if="showPaymentValidationDialog" @hideDialog="showPaymentValidationDialog = false" @paymentDone="$router.push({name: 'TicketBank'})" />
            </div>
        </div>

    </div>
</template>

<script>
import ProductBloc from "@/components/ProductBloc";
import PaymentValidationDialog from "@/components/PaymentValidationDialog";
import {pageInfoMixin} from "@/mixins/pageInfoMixin"
import GooglePay from "@/components/payment-methods/GooglePay";
import ApplePay from "@/components/payment-methods/ApplePay";
import PaymentMethodListBloc from "@/components/PaymentMethodListBloc";
import { getPaymentMethodsByDevice } from "@/assets/js/PaymentMethods"
import { getDeviceType } from "@/assets/js/Utils"
import { mapActions } from 'vuex'

const moment = require('moment');

export default {
    name: 'CustomerPayment',
    components: {GooglePay, ApplePay, ProductBloc, PaymentMethodListBloc, PaymentValidationDialog},
    mixins: [pageInfoMixin],
    data: function () {
        return {
            selectedPaymentMethod: "",
            customerPayment: {
                coupons: false,
                crossSell: false,
                firstName: false,
                lastName: false,
                phoneNumber: false,
                email: false,
                selectedPaymentMethods: [],
                bnpl: false
            },
            productCrossSell: {
                id:57370,
                name:{
                    en:"Mango sauce",
                    fr:"Gourde compote Mangue"
                },
                description:{
                    en:"Mango compote 120g",
                    fr:"Gourde compote Mangue 120g"
                },
                price:1.08,
                image:"3770002327210.jpg",
                ean:"3770002327210",
                marque:"Worldline",
                keywords:{

                },
                parentSpecificationId:null,
                category:{
                    id:1555877,
                    name:{
                        en:"Candy",
                        fr:"Épicerie Sucrée"
                    },
                    image:"1555877.jpg",
                    parentCategory:1555873,
                    sorting:999,
                    visible:true
                }
            },
            paymentsMethodsToExclude: ["paymentMethodTerminal", "paymentMethodCreditCard"],
            paymentMethods: getPaymentMethodsByDevice(getDeviceType()),
            deviceType: getDeviceType(),
            cardNumber: "",
            month: "",
            year: "",
            cvv: "",
            currentDate: moment().format("MM-YYYY"),
            shipping: "free",
            showPaymentValidationDialog: false,
            showBasket: false,
            showError: false
        }
    },
    async created () {
        this.showLoader({
            message: this.$t("ticketLoadingMessage"),
            color: this.$vuetify.theme.themes.light.primary
        })
        if(this.$route.query){
            if(this.$route.query.id){

                let customerPaymentTopic = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.PAYMENT_WAITING.format({complexId: this.$route.query.id });
                this.$mqtt.publishMqtt(customerPaymentTopic, "", () => {});

                await this.updateLocalBasketWithDatabaseBasket(this.$route.query.id)
                /*BasketService.getBasketByComplexId(this.$route.query.id).then(({data, status}) => {
                    if(status === 200){
                        this.basket = data;
                        this.nbrProducts = data.basketProducts ? data.basketProducts.length : 0;
                        this.amount = data.totalAmount;
                    }
                })*/
            }

            this.customerPayment.coupons = !!this.$route.query.c
            this.customerPayment.crossSell = !!this.$route.query.cs
            this.customerPayment.firstName = !!this.$route.query.fn
            this.customerPayment.lastName = !!this.$route.query.ln
            this.customerPayment.phoneNumber = !!this.$route.query.pn
            this.customerPayment.email = !!this.$route.query.e
            this.customerPayment.shipping = !!this.$route.query.s
            this.customerPayment.selectedPaymentMethods = this.$route.query.pm ? this.$route.query.pm.split(",") : null
            if(this.customerPayment.selectedPaymentMethods != null){
                if(!this.customerPayment.selectedPaymentMethods.includes("SIPS")){
                    this.paymentsMethodsToExclude.push("paymentMethodSips")
                }
                if(!this.customerPayment.selectedPaymentMethods.includes("osWallet")){
                    this.paymentsMethodsToExclude.push("paymentMethodGooglePay")
                    this.paymentsMethodsToExclude.push("paymentMethodApplePay")
                }
            }
        }

        this.basketProducts.forEach(basketProduct => {
            if(basketProduct.product.id === this.productCrossSell.id){
                this.customerPayment.crossSell = false
            }
        })
        if(this.customerPayment.crossSell){
            this.$root.$emit("refreshCrossSell")
        }
        this.hideLoader();
        this.showBasket = true

    },
    computed: {
        selectedPaymentMethodObject(){
            if(this.selectedPaymentMethod > -1){
                return this.paymentMethods.find(paymentMethod => this.selectedPaymentMethod == paymentMethod.id )
            }
            return null
        },
        paymentMethodsToShow(){
            return this.paymentMethods.filter(method => !this.paymentsMethodsToExclude.includes(method.name))
        },
        nextRoute(){
            if(this.selectedPaymentMethodObject && (this.selectedPaymentMethodObject.name == "paymentMethodGooglePay" || this.selectedPaymentMethodObject.name == "paymentMethodApplePay")){
                return "/ticketbank"
            }else{
                return this.selectedPaymentMethodObject.nextRoute
            }
        },
        totalAmount(){
            if(this.coupon == "PRW10"){
                return this.basketTotalAmount * 0.9
            }
            return this.basketTotalAmount
        },
        validDate(){
            return moment(`${this.month}-${this.year}`, 'MM-YYYY').isAfter(moment(this.currentDate, "MM-YYYY"))
        },
        validInfo(){
            return (new RegExp('[0-9][0-9][0-9][0-9] [0-9][0-9][0-9][0-9] [0-9][0-9][0-9][0-9] [0-9][0-9][0-9][0-9]')).test(this.cardNumber) && this.validDate && (new RegExp('[0-9][0-9][0-9][0-9]')).test(this.year) && (new RegExp('[0-9][0-9]')).test(this.month) && (new RegExp('[0-9][0-9][0-9]')).test(this.cvv)
        }
    },
    methods: {
        ...mapActions('customer', ['setCardNumber']),
        handleCheckoutClick(){
            if(this.selectedPaymentMethodObject){
                this.$router.push(this.selectedPaymentMethodObject.nextRoute)
            }else{
                this.payBtnClicked = true
            }
        },
        fillCards(){
            this.showError = false
            let nextDate = moment(this.currentDate, "MM-YYYY").add(1, 'M').format('MM-YYYY')
            this.cardNumber = "4200 0000 0000 0000"
            this.month = nextDate.split("-")[0]
            this.year = nextDate.split("-")[1]
            this.cvv = "123"
        },
        handlePayBtn(){
            this.showError = false
            if(this.validInfo && this.validDate){
                this.setCardNumber("**** **** **** " + this.cardNumber.substr(this.cardNumber.length - 4))
                this.showPaymentValidationDialog = true
            }else{
                this.showError = true
            }
            
        }
    },

}
</script>

<style scoped>
.basket-breadcrumbs{
    position: fixed;
    width: 100%;
    background: white;
    z-index: 100;
}
.payment-method-wrapper {
    border: solid 1px var(--color-primary);
}
.payment-method-icon-wrapper{
    position: relative;

}
.payment-method-icon-wrapper .color-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--color-primary);
    mix-blend-mode: color;
}
.payment-methods-footer-btn{
    width: 100%;
}
.payment-methods-footer-btn.disabled{
    opacity: 0.55;
}

>>> .v-input--is-focused fieldset, .shipping-list{
  color: rgba(0, 0, 0, 0.38) !important;
  border: 1px solid !important;
}
.without-top-border >>> fieldset{
    border-top: none !important;
}
.without-left-border >>> fieldset{
    border-left: none !important;
}

</style>

